import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import scssVars from '~components/scss-variables'
import quoteOpen from '../../img/testimonials/Quotation_mark_open.svg'
import quoteClose from '../../img/testimonials/Quotation_mark_close.svg'

const QuoteWrapper = styled.div`
  position: relative;
  display: flex;
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 35px;
    text-transform: uppercase;
    padding-left: 1.5rem;
    &:before, &:after {
      height: 32px;
      width: 45px;
      position: relative;
    }
    &:before {
      content: url(${quoteOpen});
      top: -10px;
      left: -15px;
    }
    &:after {
      content: url(${quoteClose});
      top: 25px;
      left: 15px;
    }
    @media only screen and (min-width: ${scssVars.desktopMin}) {
      font-size: 26px;
      line-height: 40px;
      letter-spacing: 1.3px;
    }
    span {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -5px;
        width: 105%;
        height: 60%;
        background-color: ${scssVars.gray};
        z-index: -10;
        transition: all .1s linear;
      }
    }
  }
`

const MainTestimonial = ({ text, className }) => (
  <QuoteWrapper className={className}>
    <p>
      {text.split(' ').map((key, i) => <span key={i}>{key} </span>)}
    </p>
  </QuoteWrapper>
)

MainTestimonial.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default MainTestimonial
