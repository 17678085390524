import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Testimonial from './Testimonial'

const MobileTestimonial = () => {
  const [count, setCount] = useState(10)
  const { allMarkdownRemark: { edges } } = useStaticQuery(graphql`
    query TestimonialsMobileQuery {
      allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "press-page"}, title: {eq: "Testimonial"}}}) {
        edges {
          node {
            frontmatter {
            lead
            body
            author
            }
          }
        }
      }
    }
  `)
  const testimonialItems = edges.map(edge => edge.node.frontmatter)
  return (
    <>
      {
        testimonialItems.slice(0, count).map((item, i) => (
          <Testimonial
            key={`testimonial-${i}`}
            lead={item.lead}
            body={item.body}
            author={item.author}
            top={i}
          />
        ))
      }
      <button
        className="btn btn-block btn-outline-primary my-5"
        onClick={() => setCount(count + 10)}
        type="button"
        disabled={count >= testimonialItems.length}
      >
        Load More
      </button>
    </>
  )
}

export default MobileTestimonial
