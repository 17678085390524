import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Container } from 'reactstrap'
import styled from 'styled-components'

import scssVars from '../components/scss-variables'
import Layout from '../components/Layout'
import Main from '../components/layouts/Main'
import Breadcrumbs from '../components/layouts/Breadcrumbs'
import MainTestimonial from '../components/Testimonials/MainTestimonial'
import Testimonial from '../components/Testimonials/Testimonial'
import MobileTestimonial from '../components/Testimonials/Mobile'
import SEO from '../components/seo'

const Heading = styled.h1`
  font-weight: 200;
  color: ${scssVars.darkBrown};
  text-align: center;
  display: block;
  flex-wrap: wrap;
  display: flex;
  font-size: 44px;
  margin-bottom: 20px;
  line-height: 1.25;
  flex-direction: column;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    flex-direction: row;
    text-align: left;
  }
`

const HeadingSpan = styled.span`
  border: 1px solid ${scssVars.brown};
  height: 1px;
  margin: 0 auto 40px;
  width: 25%;
  display: block;
  margin: 25px auto;
  height: 1px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-left: 30px;
    margin-bottom: 0;
    flex-grow: 1;
  }
`

const HeroWrapper = styled.div`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    border-bottom: 1px solid ${scssVars.gray};
  }
`

const GridContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-template-rows: repeat(5, auto);
    li:nth-child(-n+3) {
      blockquote {
        border-top: 0;
      }
    }
  }
`

const PageList = styled.ul`
  border-top: 1px solid ${scssVars.brown};
  list-style: none;
  li {
    padding: 0 0.5rem;
    a {
      color: ${scssVars.brown};
    }
    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }
`

const PullQuote = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-top: 2rem;
  margin-left: 30%;
`

const TestimonialsPage = ({ data: { allMarkdownRemark: { edges } }, pageContext: { numPages, currentPage } }) => {
  const testimonialItems = edges.map(edge => edge.node.frontmatter)
  return (
    <Layout>
      <SEO title="Testimonials" pathname="/testimonials" description="Leather Spa's customer testimonials and reviews" />
      <Main>
        <Container>
          <Breadcrumbs title="Testimonials" breadcrumb={{ title: 'About', path: '/about' }} />
          <Heading>
            Testimonials
            <HeadingSpan />
          </Heading>
          <HeroWrapper className="pt-5 pb-4">
            <MainTestimonial text={'I can\'t thank you enough for what a wonderful customer service experience this has been'} />
            <PullQuote className="d-none d-lg-block">
              &#8220;I received my repaired shoe today via UPS. There were no surprise
              border fees. The packaging was lovely, it was like opening a present.
              The service and shipping was very quick. The repair is beautiful. I
              can’t thank you enough for what a wonderful customer service experience
              this has been.&#8221;— Lisa H.
            </PullQuote>
          </HeroWrapper>
          <div className="d-lg-none">
            <MobileTestimonial />
          </div>
          <div className="d-none d-lg-block">
            <GridContainer>
              {testimonialItems.map((item, i) => (
                <Testimonial
                  key={`testimonial-${i}`}
                  lead={item.lead}
                  body={item.body}
                  author={item.author}
                  top={i}
                />
              ))}
            </GridContainer>
            <div className="text-center">
              <PageList className="d-none d-lg-inline-block text-center pl-0 my-5 ml-auto mr-auto pt-2">
                {
                  Array.from({ length: numPages }, (_, i) => (
                    <li className="d-inline" key={`pagination-number${i + 1}`}>
                      <Link to={`/testimonials/${i === 0 ? '' : i + 1}`} style={{ color: i + 1 === currentPage ? scssVars.orange : scssVars.brown }}>{i + 1}</Link>
                    </li>
                  ))
                }
              </PageList>
            </div>
          </div>
        </Container>
      </Main>
    </Layout>
  )
}

TestimonialsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export const testimonialsPageQuery = graphql`
  query TestimonialsQuery ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "press-page"}, title: {eq: "Testimonial"}}}, limit: $limit, skip: $skip) {
      edges {
        node {
          frontmatter {
          lead
          body
          author
          }
        }
      }
    }
  }
`

export default TestimonialsPage
