import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import scssVars from '~components/scss-variables'
import greyQuote from '../../img/testimonials/Quotation_mark_grey.svg'

const StyledSection = styled.li`
  display: flex;
  margin: 0;
  aside {
    padding-top: 2rem;
  }
  blockquote {
    margin-left: 1.5rem;
    padding-top: 2rem;
    border-top: 1px solid ${scssVars.gray};
    width: 100%;
  }
`

const Lead = styled.h1`
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${scssVars.darkBrown};
  margin-bottom: 1rem;
`

const Quote = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${scssVars.darkBrown};
  margin-bottom: 0;
`

const CustomerName = styled(Quote)`
  font-weight: bold;
  padding: 15px 0;
`

const Testimonial = ({
  lead,
  body,
  author
}) => {
  return (
    <StyledSection>
      <aside>
        <img src={greyQuote} />
      </aside>
      <blockquote>
        <Lead>{lead}</Lead>
        <Quote>{body}</Quote>
        { author ? <CustomerName>&#8208; {author}</CustomerName> : null }
      </blockquote>
    </StyledSection>
  )
}

Testimonial.propTypes = {
  lead: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired
}

export default Testimonial
